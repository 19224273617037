import React from 'react'
import classNames from 'classnames'
import styles from './ResponsiveContainers.module.css'

type Props = {
  children?: React.ReactNode
  className?: string
}

/* Row containers */

const SlimContainer = ({ children, className, ...props }: Props) => (
  <div className={classNames(styles.slim, className)} {...props}>
    {children}
  </div>
)

const RegularContainer = ({ children, className, ...props }: Props) => (
  <div className={classNames(styles.regular, className)} {...props}>
    {children}
  </div>
)

const WideContainer = ({ children, className, ...props }: Props) => (
  <div className={classNames(styles.wide, className)} {...props}>
    {children}
  </div>
)

/* Generic Fluid Container - This is intended to replace the row containers above
 *
 */

const Container = ({ children, className, ...props }: Props) => (
  <div className={classNames(className, styles.container)} {...props}>
    {children}
  </div>
)

export { SlimContainer, RegularContainer, WideContainer, Container }
