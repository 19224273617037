import React from 'react'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import classNames from 'classnames/bind'
import { ParsedUrlQuery } from 'querystring'
import Link from 'next/link'

import GET_DEPARTMENTS from 'modules/marketplace/common/graphql/GetDepartments.graphql'
import { GetDepartments } from 'modules/marketplace/common/graphql/__generated__/GetDepartments'

import Icon from 'components/Icon'

import styles from './DepartmentNavigation.module.css'
const cx = classNames.bind(styles)

const DEPARTMENT_PATH = '/shop/d'

const DepartmentNavigation = () => {
  const router = useRouter()
  const { superDepartmentSlug, subDepartmentSlug } = router.query
  const departmentsQuery = useQuery<GetDepartments>(GET_DEPARTMENTS)

  const departmentList = departmentsQuery.data?.departmentFullList
  const superDepartment = departmentList?.find(dept => dept.slug === superDepartmentSlug)

  const convertObjectToQueryString = (obj: ParsedUrlQuery): string => {
    return Object.entries(obj)
      .filter(([key]) => key !== 'superDepartmentSlug' && key !== 'subDepartmentSlug')
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
      .join('&')
  }

  let queryStringParams = convertObjectToQueryString({ ...router.query })
  queryStringParams = `${queryStringParams ? `?${queryStringParams}` : ''}`

  return (
    <div className={styles.departmentsContainer}>
      {!superDepartment && (
        <>
          <h4 className={styles.navigationHeading}>Departments</h4>
          {departmentList?.map(department => (
            <div key={department.id}>
              <Link href={`${DEPARTMENT_PATH}/${department.slug}${queryStringParams}`}>
                <a
                  className={cx(
                    department.slug === superDepartmentSlug ? styles.selectedDepartmentName : styles.departmentName,
                    !subDepartmentSlug && department.slug === superDepartmentSlug ? styles.disablePointerEvents : ''
                  )}>
                  {department.name}
                </a>
              </Link>
            </div>
          ))}
        </>
      )}

      {superDepartment && (
        <>
          <h4 className={styles.navigationHeading}>
            <Link href={`${DEPARTMENT_PATH}${queryStringParams}`}>
              <a className={styles.allDepartmentsLink}>
                <Icon kind="arrow-left" size={16} />
                <span>All Departments</span>
              </a>
            </Link>
          </h4>

          {subDepartmentSlug ? (
            <h4 className={styles.selectedDepartmentName}>
              <Link href={`${DEPARTMENT_PATH}/${superDepartmentSlug}${queryStringParams}`}>
                <a>{superDepartment.name}</a>
              </Link>
            </h4>
          ) : (
            <h4 className={styles.selectedDepartmentName}>{superDepartment.name}</h4>
          )}

          {superDepartment.departmentChildren.length && (
            <div className={styles.subDepartmentsContainer}>
              {superDepartment.departmentChildren
                .map(s => s.child)
                .map(subDepartment => (
                  <Link
                    href={`${DEPARTMENT_PATH}/${superDepartmentSlug}/${subDepartment?.slug}${queryStringParams}`}
                    key={subDepartment?.id}>
                    <a
                      className={cx(
                        subDepartment?.slug === subDepartmentSlug
                          ? styles.selectedDepartmentName
                          : styles.departmentName
                      )}>
                      {subDepartment?.name}
                    </a>
                  </Link>
                ))}
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default DepartmentNavigation
