import { useQuery } from '@apollo/client'

import GET_ALL_PRODUCT_CATEGORY_TAXONOMY from './graphql/GetAllProductCategoryTaxonomy.graphql'
import {
  GetAllProductCategoryTaxonomy,
  GetAllProductCategoryTaxonomy_productCategoryTaxonomies
} from './graphql/__generated__/GetAllProductCategoryTaxonomy'

export type GenerateNestedArrayArguments = {
  children?: GetAllProductCategoryTaxonomy_productCategoryTaxonomies[]
} & Pick<
  GetAllProductCategoryTaxonomy_productCategoryTaxonomies,
  'id' | 'parentId' | 'name' | 'slug' | 'slugPath' | 'hasCatalogs' | 'hasSellers' | 'hasProducts'
>

export type ProductCategoryTaxonomyDataCheck = Pick<
  GetAllProductCategoryTaxonomy_productCategoryTaxonomies,
  'hasCatalogs' | 'hasSellers' | 'hasProducts'
>

const generateNestedArray = (data: GenerateNestedArrayArguments[]) => {
  const nestedMap: { [key: string]: GenerateNestedArrayArguments[] } = {}
  const nestedArray: GenerateNestedArrayArguments[] = []

  // Create a mapping of parentId to their respective objects
  data.forEach(item => {
    const parentId = item.parentId ?? 'superCategory'

    nestedMap[parentId] = nestedMap[parentId] || []
    nestedMap[parentId].push({ ...item, children: [] })
  })

  // Recursive function to build the nested structure
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildHierarchy = (parentId: string | 'superCategory', parentObject: any) => {
    if (nestedMap[parentId]) {
      nestedMap[parentId].forEach((childItem: GenerateNestedArrayArguments) => {
        const newObj = { ...childItem, children: [] }
        if (!parentObject.children) {
          parentObject.children = []
        }
        parentObject.children.push(newObj)
        buildHierarchy(childItem.id, newObj)
      })
    }
  }

  // Build the top-level hierarchy (where parentId is null)
  buildHierarchy('superCategory', { children: nestedArray })

  return nestedArray
}

export const useProductCategoryTaxonomyHooks = () => {
  const { loading: isProductCategoryTaxonomyloading, data: productCategoryTaxonomyData } =
    useQuery<GetAllProductCategoryTaxonomy>(GET_ALL_PRODUCT_CATEGORY_TAXONOMY)
  const getNestedProductCategories = generateNestedArray(productCategoryTaxonomyData?.productCategoryTaxonomies || [])

  const getProductCategoryNameFromSlug = (slug: string | string[]) => {
    const getCategoryFromList = (slug: string) => {
      const productCategory = productCategoryTaxonomyData?.productCategoryTaxonomies.find(
        productCategory => productCategory.slug === slug
      )
      return productCategory?.name ?? ''
    }

    if (typeof slug === 'string') {
      return getCategoryFromList(slug)
    } else {
      return slug.map(slug => getCategoryFromList(slug))
    }
  }

  return {
    isProductCategoryTaxonomyloading,
    productCategoryTaxonomyData,
    getNestedProductCategories,
    getProductCategoryNameFromSlug
  }
}
