import { Fragment } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames/bind'

import { ParsedUrlQuery } from 'querystring'
import { arrayParam } from 'lib/query-helper'

import Icon from 'components/Icon'

import {
  GenerateNestedArrayArguments,
  ProductCategoryTaxonomyDataCheck,
  useProductCategoryTaxonomyHooks
} from 'hooks/useProductCategoryTaxonomyHooks'
import { ElasticsearchIndexEnum } from '../../../../../../__generated__/globalTypes'

import styles from './ProductCategoriesNavigation.module.css'
const cx = classNames.bind(styles)

const PROD_CATEGORIES_PATH = '/shop/c'

type ProductCategoriesNavigationProps = {
  hideEmpty?: ElasticsearchIndexEnum[]
}

const ProductCategoriesNavigation = ({ hideEmpty }: ProductCategoriesNavigationProps) => {
  const { isProductCategoryTaxonomyloading, getNestedProductCategories } = useProductCategoryTaxonomyHooks()
  const router = useRouter()
  const { query } = router

  const convertObjectToQueryString = (obj: ParsedUrlQuery): string => {
    if (!obj || (Object.keys(obj).length === 1 && obj.hasOwnProperty('productCategorySlug'))) {
      return ''
    }

    // Exclude 'productCategorySlug' from the query string
    const filteredObj = Object.fromEntries(Object.entries(obj).filter(([key]) => key !== 'productCategorySlug'))

    const queryString = Object.entries(filteredObj)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value as string)}`)
      .join('&')

    return queryString ? `?${queryString}` : ''
  }

  const categoryQuery: string[] = arrayParam(query.productCategorySlug) || []

  const renderNestedCategories = (
    data: GenerateNestedArrayArguments[] | undefined,
    renderFunction: (category: GenerateNestedArrayArguments) => JSX.Element,
    keyProperty: string
  ) => {
    if (!Array.isArray(data)) {
      return null
    }

    return data.map(subCategory => {
      // Function to check if a category or its children should be hidden based on the hideEmpty array
      const shouldHideCategory = (category: GenerateNestedArrayArguments) => {
        return hideEmpty?.every(indexEnum => {
          const property = `has${indexEnum.charAt(0).toUpperCase() + indexEnum.slice(1).toLowerCase()}`
          const categoryEmpty = category[property as keyof ProductCategoryTaxonomyDataCheck] === false
          const childrenEmpty = category.children?.every(
            child => child[property as keyof ProductCategoryTaxonomyDataCheck] === false
          )

          return categoryEmpty && (childrenEmpty || !category.children)
        })
      }

      // Check if the current subCategory should be hidden
      if (shouldHideCategory(subCategory)) {
        return null
      }

      // Render the subCategory
      return (
        <Fragment key={subCategory.id}>
          <div className={styles.subCategoriesContainer}>
            {renderFunction(subCategory)}
            {(subCategory.children ?? []).length > 0 &&
              categoryQuery.includes(subCategory.slug) &&
              renderNestedCategories(subCategory.children, renderFunction, keyProperty)}
          </div>
        </Fragment>
      )
    })
  }

  const renderSubCategoryTitle = (category: GenerateNestedArrayArguments) => (
    <Link
      href={`${PROD_CATEGORIES_PATH}/${category.slugPath?.join('/')}${convertObjectToQueryString(query)}
                  `}>
      <a>
        <span
          className={cx(
            category?.slug && categoryQuery.includes(category?.slug) ? styles.selectedCategoryName : styles.categoryName
          )}>
          {category?.name}
        </span>
      </a>
    </Link>
  )

  const resetProductCategoryFilters = () => {
    router.push(
      {
        pathname: PROD_CATEGORIES_PATH
      },
      undefined,
      { shallow: true }
    )
  }

  if (isProductCategoryTaxonomyloading) return null

  return (
    <div className={styles.categoriesContainer}>
      <>
        {categoryQuery.length === 0 ? (
          <h4 className={styles.navigationHeading}>Categories</h4>
        ) : (
          <h4 className={styles.navigationHeading} onClick={() => resetProductCategoryFilters()}>
            <span className={styles.allCategoriesLink}>
              <Icon kind="arrow-left" size={16} />
              <span>{`All Categories`}</span>
            </span>
          </h4>
        )}

        {getNestedProductCategories.map(category => (
          <Fragment key={category.id}>
            {categoryQuery.length === 0 || categoryQuery.includes(category.slug) ? (
              <div>
                <Link
                  href={`${PROD_CATEGORIES_PATH}/${category.slugPath?.join('/')}${convertObjectToQueryString(query)}
                  `}>
                  <a>
                    <span
                      className={cx(
                        categoryQuery.length > 0 && categoryQuery.includes(category.slug)
                          ? styles.selectedCategoryName
                          : styles.categoryName
                      )}>
                      {category.name}
                    </span>
                  </a>
                </Link>

                {/* Show subcategories recursively */}
                {categoryQuery.length > 0 &&
                  categoryQuery.includes(category.slug) &&
                  renderNestedCategories(category.children, renderSubCategoryTitle, 'id')}
              </div>
            ) : null}
          </Fragment>
        ))}
      </>
    </div>
  )
}

export default ProductCategoriesNavigation
