import classNames from 'classnames'
import UserContentImage from 'components/UserContentImage'
import styles from './SquareImage.module.css'

type SquareImageProps = {
  src?: string
  alt?: string
  cover?: boolean
  className?: string
}

const SquareImage = ({ src, alt, cover = false, className }: SquareImageProps) => (
  <div className={classNames(styles.square, className)}>
    <div className={styles.content}>
      <UserContentImage src={src} alt={alt} className={cover ? styles.fitCover : styles.fitContain} />
    </div>
  </div>
)

export default SquareImage
