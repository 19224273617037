import React, { useState, useEffect } from 'react'

import NavItem from '../../components/NavItem'
import Icon from 'components/Icon'
import OrdersDropdown from '../../components/OrdersDropdown'

import { useShoppingCart } from 'modules/buyer-hub/checkout/components/ShoppingCartContext/useShoppingCart'

import styles from '../HeaderNav/HeaderNav.module.css'

const ShoppingCartNavItem = () => {
  const [orderDropDownOpen, setOrderDropDownOpen] = useState(false)
  const {
    fetchOrdersSummary,
    fetchOrdersSummaryResult: { data }
  } = useShoppingCart()

  const toggleOrderDropDown = () => {
    setOrderDropDownOpen(prevState => !prevState)
  }

  const closeOrderDropDown = () => {
    setOrderDropDownOpen(false)
  }

  useEffect(() => {
    const loadData = async () => {
      await fetchOrdersSummary()
    }

    loadData()
  }, [fetchOrdersSummary])

  const draftOrders = data?.currentBuyer?.orders?.nodes ?? []
  const draftOrdersCount = data?.currentBuyer?.orders?.nodesCount ?? 0

  return (
    <NavItem
      dataRef="order-menu"
      disabled={draftOrdersCount === 0}
      isActive={orderDropDownOpen}
      badgeCount={draftOrdersCount}
      onClick={draftOrdersCount > 0 ? toggleOrderDropDown : undefined}>
      <Icon kind="shopping-cart" size={18} />
      {orderDropDownOpen && (
        <OrdersDropdown
          draftOrders={draftOrders}
          className={styles.dropdown}
          headerTitle="Draft Orders"
          onClickOutside={closeOrderDropDown}
        />
      )}
    </NavItem>
  )
}

export default ShoppingCartNavItem
