import Router from 'next/router'
import { useQuery } from '@apollo/client'

import { useUserSession } from 'hooks'

import FetchingPanel from 'components/FetchingPanel'
import Dropdown, { DropdownProps } from 'components/Dropdown/Dropdown'
import { DropdownItem, DropdownSection } from 'components/Dropdown'

import GET_OTHER_BUYERS_DROPDOWN_INFO from './graphql/GetOtherBuyersDropdownInfo.graphql'
import { GetOtherBuyersDropdownInfo } from './graphql/__generated__/GetOtherBuyersDropdownInfo'
import { AccountableTypeEnum } from '../../../../../../__generated__/globalTypes'

import styles from './BuyerDropdown.module.css'

export type BuyerDropdownProps = {
  children?: React.ReactNode
} & Omit<DropdownProps, 'children'>

const BuyerDropdown = ({ children, ...rest }: BuyerDropdownProps) => {
  const { currentAccount, switchAccount } = useUserSession()
  const { loading, data } = useQuery<GetOtherBuyersDropdownInfo>(GET_OTHER_BUYERS_DROPDOWN_INFO)

  const otherBuyerAccounts =
    data?.accounts.filter(account => account.type === AccountableTypeEnum.BUYER && account.id !== currentAccount?.id) ??
    []

  if (loading) return <FetchingPanel />

  return (
    <Dropdown {...rest}>
      <DropdownSection>
        <DropdownItem icon="shopping-cart" label="Order History" onClick={() => Router.push('/bh/account/orders')} />
        <DropdownItem icon="dollar-sign" label="Pricing Requests" onClick={() => Router.push('/bh/account/requests')} />
        <DropdownItem icon="settings" label="Account Settings" onClick={() => Router.push('/bh/account/settings')} />
      </DropdownSection>
      {otherBuyerAccounts.length > 0 && (
        <DropdownSection title="Other Buyer Accounts">
          <div className={styles.accountList}>
            {otherBuyerAccounts.map(account => (
              <div
                className={styles.accountListItem}
                key={account.id}
                onClick={() => {
                  switchAccount(account.id, '/')
                }}>
                <span>{account.displayName}</span>
                <small>{account.entityName}</small>
              </div>
            ))}
          </div>
        </DropdownSection>
      )}

      {children}
    </Dropdown>
  )
}

export default BuyerDropdown
