import Icon from 'components/Icon'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import styles from './PreviewBanner.module.css'

const PreviewBanner = () => (
  <div className={styles.previewBanner}>
    <Container className={styles.content}>
      <Icon className={styles.icon} kind="eye" size={16} color="var(--colorYellow10)" />
      <span>You’re viewing your products in preview mode</span>
    </Container>
  </div>
)

export default PreviewBanner
