import React from 'react'
import classNames from 'classnames'
import styles from './NavItem.module.css'

type NavItemProps = {
  badgeCount?: number
  children?: React.ReactNode
  dataRef?: string
  disabled?: boolean
  isActive?: boolean
  isPrimary?: boolean
  onClick?: () => void
  className?: string
}

const NavItem = ({
  badgeCount,
  children,
  dataRef,
  disabled,
  isActive,
  isPrimary,
  className,
  onClick
}: NavItemProps) => (
  <li
    className={classNames(
      styles.navItem,
      {
        [styles.disabled]: disabled,
        [styles.isPrimary]: isPrimary,
        [styles.isActive]: isActive
      },
      className
    )}
    data-ref={dataRef}
    onClick={onClick}>
    {children}
    {Boolean(badgeCount) && <span className={styles.badgeCount}>{badgeCount}</span>}
  </li>
)

export default NavItem
