import React, { useState } from 'react'

import classNames from 'classnames'

import BuyerDropdown from '../../components/BuyerDropdown'
import NavItem from '../../components/NavItem'
import Icon from 'components/Icon'
import { GetUserSession_currentAccount_buyer } from 'hooks/graphql/__generated__/GetUserSession'

import styles from '../HeaderNav/HeaderNav.module.css'

type BuyerAccountNavItemProps = {
  buyer: GetUserSession_currentAccount_buyer
}

const BuyerAccountNavItem = ({ buyer }: BuyerAccountNavItemProps) => {
  const [buyerDropDownOpen, setBuyerDropDownOpen] = useState(false)

  const toggleBuyerDropDown = () => {
    setBuyerDropDownOpen(prevState => !prevState)
  }

  const closeBuyerDropDown = () => {
    setBuyerDropDownOpen(false)
  }

  return (
    <NavItem isActive={buyerDropDownOpen} dataRef="buyer-menu" onClick={toggleBuyerDropDown}>
      <span className={classNames(styles.accountNavLabel, 'hideForPhone')}>{buyer.displayName}</span>
      <Icon kind="shop" size={18} />
      {buyerDropDownOpen && (
        <BuyerDropdown
          className={styles.dropdown}
          headerTitle={buyer.displayName || ''}
          headerCaption={buyer.entityName}
          onClickOutside={closeBuyerDropDown}
        />
      )}
    </NavItem>
  )
}

export default BuyerAccountNavItem
