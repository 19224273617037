import { useMemo } from 'react'

import { DefaultOptionType, defaultOption } from './GlobalSearch'

type RecentSearches = {
  values:
    | [
        {
          query: string
          type?: string
          url?: string
          searchResultUrl?: string
        }
      ]
    | null
  maxAge: number
}

const useGlobalSearch = () => {
  // Get recent queries saved in cookie
  const getRecentSearchesFromCookie: DefaultOptionType[] = useMemo(() => {
    // Retrieve the value from localStorage
    const storedData = typeof window !== 'undefined' ? localStorage.getItem('recent_searches') : null
    // Parse the JSON data and typecast it as RecentSearches
    const recentSearches: RecentSearches | null = storedData ? JSON.parse(storedData) : null

    if (!recentSearches) return [defaultOption]

    if (Array.isArray(recentSearches.values)) {
      const recentSearchesArray = recentSearches.values.map(obj => {
        return {
          label: obj.query,
          value: obj.query,
          type: obj.type,
          searchResultUrl: obj.url
        }
      })

      return [defaultOption, ...recentSearchesArray]
    }

    return []
  }, [])

  // Store new query in cookie
  const storeRecentSearchesInLocalStorage = ({ query, type, url }: { query: string; type?: string; url?: string }) => {
    // Retrieve the value from localStorage
    const storedData = typeof window !== 'undefined' ? localStorage.getItem('recent_searches') : null
    // Parse the JSON data and typecast it as RecentSearches
    const recentSearches: RecentSearches | null = storedData ? JSON.parse(storedData) : null

    // Store the query for one week duration
    const maxAge = new Date().getTime() + 1000 * 60 * 60 * 24 * 7
    const searchObj = {
      query,
      type,
      url
    }

    if (recentSearches?.values) {
      // Check if the query is already present in the recentSearches, if no, add it
      const isQueryInRecentSearches = recentSearches.values.some(
        recentQuery => recentQuery.query?.toLowerCase() === query?.toLowerCase()
      )

      if (!isQueryInRecentSearches) {
        recentSearches.values.push(searchObj)
      }
      if (recentSearches.values.length > 5) {
        // Remove all elements from start until length is 5
        recentSearches.values.splice(0, recentSearches.values.length - 5)
      }
      recentSearches.maxAge = maxAge

      localStorage.setItem('recent_searches', JSON.stringify(recentSearches))
    } else {
      localStorage.setItem('recent_searches', JSON.stringify({ values: [searchObj], maxAge }))
    }
  }

  const checkAndDeleteRecentSearches = () => {
    // Retrieve the value from localStorage
    const storedData = typeof window !== 'undefined' ? localStorage.getItem('recent_searches') : null
    // Parse the JSON data and typecast it as RecentSearches
    const recentSearches: RecentSearches | null = storedData ? JSON.parse(storedData) : null

    if (recentSearches?.maxAge) {
      const currentTime = new Date().getTime()
      if (currentTime > recentSearches.maxAge) {
        localStorage.removeItem('recent_searches')
      }
    }
  }

  return {
    storeRecentSearchesInLocalStorage,
    getRecentSearchesFromCookie,
    checkAndDeleteRecentSearches
  }
}

export default useGlobalSearch
