import React, { useState, useEffect } from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'
import classNames from 'classnames/bind'

import GlobalSearch from 'modules/marketplace/search/components/GlobalSearch'
import Icon from 'components/Icon'
import Logo from 'components/Logo'
import { Container } from 'modules/marketplace/layout/components/ResponsiveContainers'
import NavItem from '../../components/NavItem'
import GuestBanner from '../../components/GuestBanner'
import PreviewBanner from '../../components/PreviewBanner'
import FinishSignupButton from '../../components/FinishSignupButton'
import BuyerAccountNavItem from '../BuyerAccountNavItem'
import ShoppingCartNavItem from '../ShoppingCartNavItem'

import UserDropdown from '../../components/UserDropdown'
import { useUserSession } from 'hooks'
import ButtonLink from 'components/ButtonLink'

import styles from './HeaderNav.module.css'
import { BuyerStatusEnum } from '../../../../../../__generated__/globalTypes'
const cx = classNames.bind(styles)

export interface HeaderNavProps {
  shouldHideSignupButton?: boolean
  shouldHideLoginButton?: boolean
  shouldHideUserDropdown?: boolean
  shouldHideFinishSignupButton?: boolean
  isPreview?: boolean
  className?: string
}

const HeaderNav = ({ isPreview, className, ...props }: HeaderNavProps) => {
  const [returnToUrl, setReturnToUrl] = useState<string | null>(null)
  const [userDropDownOpen, setUserDropDownOpen] = useState(false)
  const [shouldHideSignupButton, setShouldHideSignupButton] = useState(props.shouldHideSignupButton ?? false)
  const [shouldHideLoginButton, setShouldHideLoginButton] = useState(props.shouldHideLoginButton ?? false)

  const toggleUserDropDown = () => {
    setUserDropDownOpen(prevState => !prevState)
  }

  const closeUserDropDown = () => {
    setUserDropDownOpen(false)
  }

  const { isGuest, currentUser: user, currentAccount } = useUserSession()
  const buyer = currentAccount?.buyer
  const rep = currentAccount?.rep
  const router = useRouter()

  const isBuyerBanned = buyer?.status === BuyerStatusEnum.BANNED

  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    // NOTE: I don't think this actually does anything, since very few URLs actually have /catalogs or /seller in them
    if (router.route.match(/\/?(catalogs?|seller)/)) {
      setReturnToUrl(`?returnTo=${encodeURIComponent(router.asPath)}`)
    }

    setShouldHideSignupButton(
      props.shouldHideSignupButton || Boolean(router.route.match(/^\/(wholesaler|signup|accept-invitation)/))
    ),
      setShouldHideLoginButton(props.shouldHideLoginButton || Boolean(router.route.match(/^\/(accept-invitation)/)))

    /* Add a border to the bottom of the header when we scroll */
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0)
    }
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [router.isReady, props.shouldHideLoginButton, props.shouldHideSignupButton, router.asPath, router.route])

  return (
    <nav className={cx(styles.navBar, { isScrolled }, className)}>
      {isPreview && <PreviewBanner />}
      {isGuest && <GuestBanner />}

      <Container>
        <div className={styles.innerContainer}>
          <div className={styles.logoAndSearch}>
            <div className={styles.fieldfolioLogo}>
              <Link href="/">
                <a className={styles.logo}>
                  <Logo />
                </a>
              </Link>
            </div>

            <div className={styles.searchWrapper}>
              <GlobalSearch />
            </div>
          </div>

          {isGuest ? (
            <ul className={styles.navList}>
              {!shouldHideSignupButton && (
                <ButtonLink
                  kind="transparent"
                  className={styles.sellButton}
                  href="/wholesaler"
                  label="Sell on Fieldfolio"
                  target="_blank"
                />
              )}
              {!shouldHideLoginButton && (
                <ButtonLink
                  kind="transparent"
                  className={styles.loginButton}
                  href={`/login${returnToUrl ? returnToUrl : ''}`}
                  label="Login"
                />
              )}
              {!shouldHideSignupButton && (
                <ButtonLink
                  kind="dark"
                  className={styles.signupButton}
                  href={`/signup/buyer${returnToUrl ? returnToUrl : ''}`}
                  label="Signup to Shop"
                />
              )}
            </ul>
          ) : (
            <ul className={styles.navList}>
              {!props.shouldHideFinishSignupButton && !isPreview && (
                <NavItem>
                  <FinishSignupButton />
                </NavItem>
              )}

              {buyer && (
                <>
                  <BuyerAccountNavItem buyer={buyer} />
                  {!isBuyerBanned && <ShoppingCartNavItem />}
                </>
              )}

              {rep != null && user != null && (
                <>
                  <NavItem dataRef="rep-menu" className={styles.repNavNameContainer}>
                    <span className={cx(styles.accountNavLabel, 'hideForPhone')}>
                      {rep.name ?? `${user.firstName} ${user.lastName}`}
                    </span>
                  </NavItem>
                </>
              )}

              {!props.shouldHideUserDropdown && (
                <NavItem isActive={userDropDownOpen} dataRef="user-menu" onClick={toggleUserDropDown}>
                  <Icon kind="user" size={18} />
                  {userDropDownOpen && user != null && (
                    <UserDropdown
                      className={styles.dropdown}
                      headerTitle={`${user.firstName} ${user.lastName}`}
                      headerCaption={user.email}
                      onClickOutside={closeUserDropDown}
                    />
                  )}
                </NavItem>
              )}
            </ul>
          )}
        </div>
      </Container>
    </nav>
  )
}

export default HeaderNav
