import React from 'react'
import classNames from 'classnames'
import styles from './GridContainer.module.css'

type Props = {
  children?: React.ReactNode
  className?: string
}

const GridContainer = ({ children, className, ...props }: Props) => (
  <div className={classNames(styles.grid, className)} {...props}>
    {children}
  </div>
)
export default GridContainer
