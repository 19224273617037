/* eslint-disable @typescript-eslint/no-explicit-any */

import qs from 'qs'

export type QueryStringParams = Record<string, string | string[] | number | undefined | null | boolean>

// queryObject()
// Why not just qs, or queryString? Because I couldn't see how to easily reject
// an object key whose value is an empty array, plus I don't actually want a
// query string, I want something close (a query object with concatenated
// string values) that can be passed directly to nextjs Router.replace().
function queryObject(object: any): any {
  const query: any = {}

  Object.entries(object || {}).forEach(([key, value]: [string, any]) => {
    if (value && value.length > 0) {
      query[key] = typeof value === 'string' ? value : value.join(',')
    }
  })

  return query
}

function queryString(object: any): string {
  return qs.stringify(queryObject(object), {
    addQueryPrefix: true,
    arrayFormat: 'comma'
  })
}

const stringParam = (p: string | string[] | undefined): string | undefined => {
  if (Array.isArray(p)) return p[0]
  else return p
}

const arrayParam = (p: string | string[] | undefined): string[] | undefined => {
  if (p === undefined) return undefined
  if (!Array.isArray(p)) {
    if (typeof p === 'string') return p.split(',')
    return [p]
  }
  return p
}

export { queryObject, queryString, stringParam, arrayParam }
