import { useRouter } from 'next/router'
import { arrayParam, QueryStringParams, stringParam } from 'lib/query-helper'
import CheckboxRadio from 'components/CheckboxRadio'

import styles from './SearchFilters.module.css'

export interface FilterGroup {
  heading: string
  name: string
  isMultiSelect?: boolean
  options: Record<string, string>[]
}

export const sellersFromFilterGroup: FilterGroup = {
  heading: 'Sellers from',
  name: 'sellersFrom',
  isMultiSelect: true,
  options: [
    {
      label: 'Show all',
      value: 'all'
    },
    {
      label: 'Australia',
      value: 'AU'
    },
    {
      label: 'New Zealand',
      value: 'NZ'
    }
  ]
}

export const shipsToFilterGroup: FilterGroup = {
  heading: 'Ships to',
  name: 'shipsTo',
  isMultiSelect: true,
  options: [
    {
      label: 'Show all',
      value: 'all'
    },
    {
      label: 'Australia',
      value: 'AU'
    },
    {
      label: 'New Zealand',
      value: 'NZ'
    }
  ]
}

export const freeShippingOverFilterGroup: FilterGroup = {
  heading: 'Shipping',
  name: 'freeShippingOver',
  isMultiSelect: true,
  options: [
    {
      label: 'Free Shipping',
      value: 'true'
    }
  ]
}

export const minimumOrderFilterGroup: FilterGroup = {
  heading: 'Minimum Order',
  name: 'minimumTotalOrderValue',
  isMultiSelect: false,
  options: [
    {
      label: 'Show all',
      value: 'all'
    },
    {
      label: 'No Minimum',
      value: 'none'
    },
    {
      label: '$200 or less',
      value: '200'
    },
    {
      label: '$500 or less',
      value: '500'
    },
    {
      label: '$1000 or less',
      value: '1000'
    }
  ]
}

const SearchFilters = () => {
  const router = useRouter()

  const filters = {
    department: arrayParam(router.query.departments) || ['all'],
    shipsTo: arrayParam(router.query.shippingCountries) || ['all'],
    sellersFrom: arrayParam(router.query.sellerCountries) || ['all'],
    freeShippingOver: arrayParam(router.query.freeShippingOver) || [],
    minimumTotalOrderValue: stringParam(router.query.minimumTotalOrderValue) || ['all']
  }

  const handleFilterChange = (filterGroup: FilterGroup, id: string) => {
    let params

    // If radio button with only a single option selectable
    if (!filterGroup.isMultiSelect) {
      params = {
        ...filters,
        [filterGroup.name]: [id]
      }
      // If checkboxes with multiple options selectable
    } else {
      const filterGroupValues = filters[filterGroup.name as keyof typeof filters] as string[]

      // Reset to all
      if (id === 'all' || (filterGroupValues.includes(id) && filterGroupValues.length === 1)) {
        // Special case for free shipping over, just remove the freeShippingOver value
        if (filterGroup.name === 'freeShippingOver' && id === 'true') {
          params = {
            ...filters,
            [filterGroup.name]: []
          }
        } else {
          params = {
            ...filters,
            [filterGroup.name]: ['all']
          }
        }
      }

      // Option is one of many currently checked, unchecked it
      else if (filterGroupValues.includes(id)) {
        params = {
          ...filters,
          [filterGroup.name]: filterGroupValues.filter(
            selectedOption => selectedOption != id && selectedOption != 'all'
          )
        }
        // Default - add option to existing selection
      } else {
        params = {
          ...filters,
          [filterGroup.name]: [...filterGroupValues.filter(selectedOption => selectedOption != 'all'), id]
        }
      }
    }

    const query: QueryStringParams = {
      ...router.query,
      freeShippingOver: params.freeShippingOver.length > 0 ? params.freeShippingOver.join(',') : null,
      shippingCountries: params.shipsTo.includes('all') ? null : params.shipsTo.join(','),
      sellerCountries: params.sellersFrom.includes('all') ? null : params.sellersFrom.join(','),
      minimumTotalOrderValue: params.minimumTotalOrderValue.includes('all') ? null : params.minimumTotalOrderValue
    }

    // Remove empty filters from query
    for (const [key, value] of Object.entries(query)) {
      if (Array.isArray(value)) {
        query[key] = value.join(',')
      }
      if (!query[key]) {
        delete query[key]
      }
    }
    // Remove page / pagination
    // If we're refiltering catalogs, we want to go back ot the start of the new results
    if (query.page) {
      delete query.page
    }

    router.push({
      pathname: router.pathname,
      query
    })
  }

  return (
    <div className={styles.filtersContainer}>
      <div>
        <p className={styles.filterHeading}>{sellersFromFilterGroup.heading}</p>
        {sellersFromFilterGroup.options.map((option, index) => (
          <CheckboxRadio
            key={index}
            name={sellersFromFilterGroup.name}
            type={sellersFromFilterGroup.isMultiSelect ? 'checkbox' : 'radio'}
            onChange={() => handleFilterChange(sellersFromFilterGroup, option.value)}
            label={option.label}
            checked={filters[sellersFromFilterGroup.name as keyof typeof filters].includes(option.value)}
            className={styles.field}
          />
        ))}
      </div>

      <div className={styles.divider} />

      <div>
        <p className={styles.filterHeading}>{shipsToFilterGroup.heading}</p>
        {shipsToFilterGroup.options.map((option, index) => (
          <CheckboxRadio
            key={index}
            name={shipsToFilterGroup.name}
            type={shipsToFilterGroup.isMultiSelect ? 'checkbox' : 'radio'}
            onChange={() => handleFilterChange(shipsToFilterGroup, option.value)}
            label={option.label}
            checked={filters[shipsToFilterGroup.name as keyof typeof filters].includes(option.value)}
            className={styles.field}
          />
        ))}
      </div>

      <div className={styles.divider} />

      <div>
        <p className={styles.filterHeading}>{freeShippingOverFilterGroup.heading}</p>
        {freeShippingOverFilterGroup.options.map((option, index) => (
          <CheckboxRadio
            key={index}
            name={freeShippingOverFilterGroup.name}
            type={freeShippingOverFilterGroup.isMultiSelect ? 'checkbox' : 'radio'}
            onChange={() => handleFilterChange(freeShippingOverFilterGroup, option.value)}
            label={option.label}
            checked={filters[freeShippingOverFilterGroup.name as keyof typeof filters].includes(option.value)}
            className={styles.field}
          />
        ))}
      </div>

      <div className={styles.divider} />

      <div>
        <p className={styles.filterHeading}>{minimumOrderFilterGroup.heading}</p>
        {minimumOrderFilterGroup.options.map((option, index) => (
          <CheckboxRadio
            key={index}
            name={minimumOrderFilterGroup.name}
            type={minimumOrderFilterGroup.isMultiSelect ? 'checkbox' : 'radio'}
            onChange={() => handleFilterChange(minimumOrderFilterGroup, option.value)}
            label={option.label}
            checked={filters[minimumOrderFilterGroup.name as keyof typeof filters].includes(option.value)}
            className={styles.field}
          />
        ))}
      </div>
    </div>
  )
}

export default SearchFilters
