import React from 'react'
import styles from './UserContentImage.module.css'
import classnames from 'classnames'

import Icon from 'components/Icon'

const rewriteS3Url = (url: string) => url.replace(/^http:\/\//i, 'https://')

type UserContentImageProps = {
  src?: string | null
  alt?: string
  iconSize?: number
  className?: string
  onClick?: (event: React.MouseEvent) => void
}

const UserContentImage = ({ src, alt, iconSize = 32, className, ...props }: UserContentImageProps) =>
  src ? (
    <img src={rewriteS3Url(src)} {...props} className={className} alt={alt ? alt : ''} />
  ) : (
    <Icon className={classnames(styles.placeholderIcon, className)} kind="image" size={iconSize} />
  )

export default UserContentImage
