import React from 'react'
import Link from 'next/link'

import ButtonLink from 'components/ButtonLink'
import Dropdown, { DropdownProps } from 'components/Dropdown/Dropdown'
import styles from './OrdersDropdown.module.css'
import { GetDraftOrdersSummary_currentBuyer_orders_nodes } from 'modules/buyer-hub/checkout/components/ShoppingCartContext/graphql/__generated__/GetDraftOrdersSummary'
import { formatCurrency } from 'lib/currencies'

type OrdersDropdownProps = {
  draftOrders: GetDraftOrdersSummary_currentBuyer_orders_nodes[]
  children?: React.ReactNode
} & Omit<DropdownProps, 'children'>

const OrdersDropdown = ({ draftOrders, children, ...rest }: OrdersDropdownProps) => (
  <Dropdown className={styles.dropdown} headerTitle="Draft Orders" {...rest}>
    {draftOrders.map(order => {
      const numItems = order.orderLineItems?.length ?? 0

      return (
        <div className={styles.orderContainer} key={order.id}>
          <div className={styles.orderHeader}>
            <span className={styles.orderLabel}>
              {order.seller != null && order.catalog != null && (
                <Link href={`/${order.seller?.slug}/${order.catalog?.slug}`} passHref>
                  <a>{order.catalogName}</a>
                </Link>
              )}
              {order.seller == null || (order.catalog == null && <>{order.catalogName}</>)}
            </span>
            <small className={styles.orderCaption}>{order.seller?.displayName}</small>
          </div>

          {numItems > 0 && (
            <div className={styles.orderTotals}>
              <div>
                {numItems} Product
                {numItems > 1 && <>s</>}
              </div>
              <div>{formatCurrency(order.totalAmount, order.currencyCode)}</div>
            </div>
          )}

          <div className={styles.orderFooter}>
            <ButtonLink
              href={`/bh/checkout/order/${order.id}/cart`}
              isBlock
              label="Checkout now"
              icon="arrow-right"
              iconPosition="right"
            />
          </div>
        </div>
      )
    })}

    {children}
  </Dropdown>
)

export default OrdersDropdown
